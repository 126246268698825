import { AmpHeader, SideBar,NavBar} from '../../components';
import { Outlet } from 'react-router';

import './styles.css';

export default function MainLayout() {
  return (
    <main className="mainContainer">
      <NavBar />
      <section className="mainSection">
        <div className="rightSection">
          <div className="contentContainer">
            {/** child component */}
            <Outlet />
          </div>

          <footer>&#174; 2022 AmpliFi | All rights reserved</footer>
        </div>
      </section>
    </main>
  );
}
